/**
 * Копирует текст в буфер обмена
 *
 * @param text - текст, копируемый в буфер обмена
 * @param successText - опционально, текст успешного копирования
 * @param errorText - опционально, текст ошибки
 *
 */

interface IProps {
    text: string;
    successText?: string;
    errorText?: string;
}

const copyTextToClipboard = async ({ errorText, successText, text }: IProps) => {
    try {
        await navigator.clipboard.writeText(text);
        console.log(successText ?? 'Скопировано');
    } catch (error) {
        console.error(errorText ?? `Ошибка:, ${error}`);
    }
};

export default copyTextToClipboard;
