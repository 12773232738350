import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import ModalContext from 'store/context/ModalContext';
import { clearStorage } from 'store/reducers/app';
import { logOut } from 'store/reducers/user';
import { PATH_ADMIN, PATH_ADMIN_FEEDBACK, PATH_NEWS, PATH_PROFILE } from 'router/constants';
import Breadcrumbs from 'modules/breadcrumbs';
import Button from 'components/button/';
import { IStore } from 'assets/ts/types';
import 'modules/header/index.scss';

const Header = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: IStore) => state);
    const { setData: setModal } = useContext(ModalContext);

    const { firstName, lastName, patronymic, showGravitonLkLink, notificationsAmount, flags } = user;
    const hasNotifications = Object.values(notificationsAmount).some(value => !!value);

    const onLogOut = async () => {
        dispatch(logOut());
        dispatch(clearStorage());
    };

    const onShowFeedbackForm = () => {
        setModal({
            title: 'Новое обращение',
            moduleName: 'FeedbackForm',
            props: {
                isNew: true,
            },
        });
    };

    return (
        <header className="header">
            <div className="header__container">
                <div className="header__breadcrumbs">
                    <Breadcrumbs />
                </div>
                <div className={cx([`header__user`, { '_has-notifications': hasNotifications }])}>
                    <div className="header__user-head">
                        <Link to={`/${PATH_PROFILE}`} className="header__user-name" title="Личный кабинет">
                            {lastName}
                            {firstName ? ` ${firstName[0]}.` : ''}
                            {patronymic ? `${patronymic[0]}.` : ''}
                        </Link>
                        <div className="header__user-avatar"></div>
                        <div className="header__user-bell">
                            <div className="icon" />
                        </div>
                    </div>
                    <div className="header__user-body">
                        <div className="header__user-action">
                            <div className="header__user-action-links">
                                {showGravitonLkLink && (
                                    <a href="https://graviton.ru/lk/" target="_blank" className="header__user-action-link link _lk-graviton">
                                        Перейти в ЛК Гравитон
                                        <div className="icon" />
                                    </a>
                                )}
                                {flags.includes('action_app_profile_news_link') && (
                                    <Link to={`/${PATH_NEWS}`} className="header__user-action-link link _notifications">
                                        <span>Новости</span>
                                        {notificationsAmount.news ? <div className="amount">{notificationsAmount.news}</div> : ''}
                                    </Link>
                                )}
                                <Button styleType="text" className="header__user-action-link _feedback" onClick={onShowFeedbackForm}>
                                    Обратная связь
                                </Button>
                                {flags.includes('action_app_feedback_admin_notifications') && (
                                    <Link to={`/${PATH_ADMIN}/${PATH_ADMIN_FEEDBACK}`} className="header__user-action-link link _notifications">
                                        <span>Обратная связь. Админка</span>
                                        {notificationsAmount.feedback ? <div className="amount">{notificationsAmount.feedback}</div> : ''}
                                    </Link>
                                )}
                            </div>
                            <Button styleType="primary" className="header__user-btn" onClick={() => onLogOut()}>
                                Выйти
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
